/** @jsx jsx */
import { jsx } from "theme-ui"

const Icon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="150"
    height="150"
    viewBox="0 0 150 150"
    fill="none"
    className={className}
  >
    <path
      fill="#624412"
      d="M119.749 50.423a44.349 44.349 0 00-72.591-34.266 44.347 44.347 0 0019.946 77.755 1.424 1.424 0 011.145 1.401v15.193a1.433 1.433 0 01-1.43 1.431h-8.584a7.15 7.15 0 00-7.153 7.152 7.153 7.153 0 007.153 7.153h8.583a1.432 1.432 0 011.43 1.431v8.583a7.153 7.153 0 1014.306 0v-8.583a1.432 1.432 0 011.43-1.431h8.584a7.15 7.15 0 007.153-7.153 7.15 7.15 0 00-7.153-7.152h-8.583a1.432 1.432 0 01-1.43-1.431V95.342a1.425 1.425 0 011.144-1.402 44.384 44.384 0 0036.05-43.517zm-74.39 0a30.042 30.042 0 1130.042 30.041A30.076 30.076 0 0145.36 50.423z"
    ></path>
    <path
      fill="#624412"
      d="M119.749 50.423a44.349 44.349 0 00-72.591-34.266 44.347 44.347 0 0019.946 77.755 1.424 1.424 0 011.145 1.401v15.193a1.433 1.433 0 01-1.43 1.431h-8.584a7.15 7.15 0 00-7.153 7.152 7.153 7.153 0 007.153 7.153h8.583a1.432 1.432 0 011.43 1.431v8.583a7.153 7.153 0 1014.306 0v-8.583a1.432 1.432 0 011.43-1.431h8.584a7.15 7.15 0 007.153-7.153 7.15 7.15 0 00-7.153-7.152h-8.583a1.432 1.432 0 01-1.43-1.431V95.342a1.425 1.425 0 011.144-1.402 44.384 44.384 0 0036.05-43.517zm-74.39 0a30.042 30.042 0 1130.042 30.041A30.076 30.076 0 0145.36 50.423z"
    ></path>
  </svg>
)

export default Icon
