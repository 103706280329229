/** @jsx jsx */
import { jsx, Grid } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"

import GridItem from "./grid-item"

const GridOne = () => {
  const data = useStaticQuery(graphql`
    query ServicesGridOneQuery {
      children: file(relativePath: { eq: "home-children-youth-services.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 730) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      urban: file(relativePath: { eq: "home-urban-health.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      newcomer: file(relativePath: { eq: "home-newcomer-services.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      seniors: file(relativePath: { eq: "home-independent-living-seniors.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Grid
      sx={{ my: 5, width: "100%", gridTemplateRows: "350px" }}
      columns={3}
      gap={3}
    >
      <GridItem
        column="1 / span 2"
        row="1"
        to="/children-youth-services"
        image={data.children.childImageSharp.fluid}
      >
        Children &amp; Youth Services
      </GridItem>
      <GridItem
        column="3"
        row="1 / span 2"
        to="/urban-health"
        image={data.urban.childImageSharp.fluid}
      >
        Urban Health
      </GridItem>
      <GridItem
        column="1"
        row="2"
        to="/newcomer-services"
        image={data.newcomer.childImageSharp.fluid}
      >
        Newcomer Services
      </GridItem>
      <GridItem
        column="2"
        row="2"
        to="/independent-living-seniors"
        image={data.seniors.childImageSharp.fluid}
      >
        Independent Living &amp; Seniors
      </GridItem>
    </Grid>
  )
}

export default GridOne
