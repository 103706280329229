/** @jsx jsx */
import { jsx } from "theme-ui"

const Icon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="150"
    height="150"
    viewBox="0 0 150 150"
    fill="none"
    className={className}
  >
    <path
      fill="#624412"
      fillRule="evenodd"
      d="M62.005 12a6 6 0 016-6h14.001a6 6 0 010 12h-1v17.02a41.11 41.11 0 0118.444 7.553l12.09-11.919-.674-.665a6 6 0 018.424-8.545l4.998 4.927.02.019 5 4.93a6 6 0 01-8.424 8.545l-.797-.786-12.106 11.934a39.995 39.995 0 017.582 18.081h17.448v-.9a6 6 0 1112 0v13.801a6 6 0 11-12 0v-.9l-17.448-.001a39.995 39.995 0 01-7.582 18.081l12.106 11.934.797-.786a6 6 0 018.424 8.546l-5 4.93-.02.019-4.998 4.927a6 6 0 01-8.424-8.546l.674-.665-12.09-11.919a41.105 41.105 0 01-18.445 7.552v17.022h1.001a6 6 0 110 12H68.005a6 6 0 010-12h1v-17.022a41.105 41.105 0 01-18.443-7.552L38.47 119.534l.674.665a6 6 0 11-8.424 8.546l-5-4.928-.017-.017-5.001-4.931a6 6 0 118.424-8.546l.797.786L42.03 99.175a39.989 39.989 0 01-7.582-18.08H17v.9a6 6 0 01-12 0V68.193a6 6 0 0112 0v.901h17.448a39.99 39.99 0 017.582-18.08L29.924 39.08l-.797.785a6 6 0 01-8.424-8.546l5-4.93.019-.018 4.999-4.927a6 6 0 018.424 8.546l-.675.664 12.091 11.92a41.109 41.109 0 0118.444-7.553V18h-1a6 6 0 01-6-6zm13 34.59c-16.099 0-29.002 12.842-29.002 28.504s12.903 28.505 29.002 28.505c16.1 0 29.003-12.843 29.003-28.505 0-15.662-12.903-28.505-29.002-28.505z"
      clipRule="evenodd"
    ></path>
  </svg>
)

export default Icon
