/** @jsx jsx */
import { jsx, Styled, Box } from "theme-ui"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const GridItem = ({ to, image, children, column, row }) => (
  <Box
    sx={{
      gridColumn: column,
      gridRow: row,
      height: "100%",
      minHeight: ["200px", "350px"],
    }}
  >
    <Link
      to={to}
      sx={{
        display: "block",
        textDecoration: "none",
        height: "100%",
        borderRadius: "default",
        overflow: "hidden",
        borderBottom: "1rem solid",
        borderBottomColor: "primary",
        transition: "border-width 100ms ease-in",
        "&:hover": {
          borderBottomWidth: "2rem",
          borderBottomColor: "secondary",
        },
      }}
    >
      <BackgroundImage
        fluid={image}
        sx={{
          backgroundSize: "cover",
          height: "100%",
          p: 0,
          position: "relative",
        }}
      >
        <Styled.h2
          sx={{
            bottom: 0,
            color: "background",
            left: 0,
            my: 0,
            p: 3,
            pt: 4,
            position: "absolute",
            backgroundImage:
              "linear-gradient(to top, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.9) 20%, rgba(0,0,0,0) 100%)",
            width: "100%",
          }}
        >
          {children}
        </Styled.h2>
      </BackgroundImage>
    </Link>
  </Box>
)

export default GridItem
