/** @jsx jsx */
import { jsx, Flex, Box, Styled, Button } from "theme-ui"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import { alpha } from "@theme-ui/color"

import { Container, Row, Col } from "components/layout"
import { Large, Medium } from "components/type"

import Layout from "components/layout"
import Panel from "components/panel"

import { GridOne, GridTwo } from "components/services"

// Logos
import Tng from "components/svgs/logo-tng"
import Sschto from "components/svgs/logo-sschto"
import Nlss from "components/svgs/logo-nlss"
import Cnh from "components/svgs/logo-cnh"

// Icons
import Walking from "components/svgs/home-people-walking-into-buildings"
import Virus from "components/svgs/home-virus"
import Female from "components/svgs/home-female"

// Docs
import External from "components/svgs/external"
import docName1 from "pdf/leadership-report.pdf"

const IndexPage = ({ data }) => (
  <Layout title="Home">
    <Img fluid={data.hero.childImageSharp.fluid} alt="" />
    <Flex>
      <Flex
        sx={{
          width: ["50%", "40%"],
          bg: "primary",
          alignItems: "center",
        }}
      >
        <Box sx={{ px: [3, 4], py: [4, 5] }}>
          <Styled.h1 as="p" sx={{ color: "background", my: 0 }}>
            <span
              sx={{ display: "block", fontSize: [5, 6, 7], lineHeight: "1" }}
            >
              Stronger
            </span>
            <span
              sx={{ display: "block", fontSize: [6, 7, 8], lineHeight: "1" }}
            >
              Together
            </span>
          </Styled.h1>
        </Box>
      </Flex>
      <Flex
        sx={{
          width: ["50%", "60%"],
          bg: "secondary",
          alignItems: "center",
        }}
      >
        <Box sx={{ px: [3, 4], py: [4, 5] }}>
          <Box
            sx={{
              height: "0.625rem",
              width: "3rem",
              mb: 3,
              bg: alpha("dark", 0.8),
            }}
          ></Box>
          <Styled.h2 as="p" sx={{ color: alpha("dark", 0.8), my: 0 }}>
            <span sx={{ display: "block", lineHeight: "1" }}>2019 - 2020</span>
            <span
              sx={{ display: "block", lineHeight: "1", fontWeight: "body" }}
            >
              Annual Report
            </span>
          </Styled.h2>
        </Box>
      </Flex>
    </Flex>

    <Container wide>
      <Flex
        sx={{
          alignItems: "center",
          justifyContent: "space-around",
          flexWrap: ["wrap", "wrap", "nowrap"],
          mb: 6,
          mt: 4,
        }}
      >
        <Tng sx={{ mb: 3, width: ["50%", "50%", "25%"] }} />
        <Nlss sx={{ mb: 3, width: ["50%", "50%", "25%"] }} />
        <Cnh sx={{ mb: 3, width: ["50%", "50%", "25%"] }} />
        <Sschto sx={{ mb: 3, width: ["50%", "50%", "25%"] }} />
      </Flex>
    </Container>

    <Container>
      <section sx={{ mb: 6 }}>
        <Row sx={{ flexDirection: ["column", "row"] }}>
          <Col sx={{ width: ["100%", "65%"] }}>
            <Styled.h1 as="h2">
              Stronger <span sx={{ color: "primary" }}>Together!</span>
            </Styled.h1>
            <Styled.p>
              “Stronger Together”. These words have never been more reflective
              of our values. This year we faced unprecedented challenges and
              have come together to continue building thriving neighbourhoods
              welcoming to all.
            </Styled.p>
            <Styled.p>
              We are so amazed at the commitment of our staff and are grateful
              to our volunteers, Board members, peer leaders, donors and friends
              who have risen to the challenge during the pandemic to provide
              incredible care with heart, resilience, and creativity.
            </Styled.p>
            <Styled.p>
              <Button
                variant="underline"
                as="a"
                target="_blank"
                href={docName1}
              >
                View our Full Leadership Report{" "}
                <External sx={{ ml: 2, height: "0.8rem", width: "0.8rem" }} />
              </Button>
              <Button
                variant="underline"
                as={Link}
                to="/our-merger"
                sx={{ ml: 2 }}
              >
                Our Merger
              </Button>
            </Styled.p>
            <Styled.h2 sx={{ color: "primary" }}>Our Mission</Styled.h2>
            <Styled.p>
              The Neighbourhood Group serves people at every stage of their
              lives, promotes independence and dignity, and engages the skills
              and talents of our neighbours to build a vibrant community.
            </Styled.p>
            <Styled.p>
              <Button variant="underline" as={Link} to="/services-programs">
                View our Services & Programs
              </Button>
              <Button
                variant="underline"
                as={Link}
                to="/financials"
                sx={{ ml: 2 }}
              >
                View our Financials
              </Button>
            </Styled.p>
          </Col>
          <Col sx={{ width: ["100%", "25%", "18%"], ml: [0, 4], display: ["none", "block", "block"] }}>
            <Flex sx={{ flexDirection: ["row", "column"] }}>
              <Img
                fluid={data.bill.childImageSharp.fluid}
                alt="Bill Sinclair: President and Chief Executive Officer"
                sx={{ my: 3, borderRadius: "default" }}
              />
              Bill Sinclair: President and Chief Executive Officer
              <Img
                fluid={data.cathy.childImageSharp.fluid}
                alt="athy Hennessey: Co-Chair, Board of Directors"
                sx={{ my: 3, borderRadius: "default" }}
              />
              Cathy Hennessey: Co-Chair, Board of Directors
              <Img
                fluid={data.samira.childImageSharp.fluid}
                alt="Samira Viswanathan: Co-Chair, Board of Directors"
                sx={{ my: 3, borderRadius: "default" }}
              />
              Samira Viswanathan: Co-Chair, Board of Directors
            </Flex>
          </Col>
        </Row>
      </section>
      <section>
        <Styled.h1>Services</Styled.h1>
        <GridOne />
      </section>
    </Container>
    <section sx={{ mb: [4, 6] }}>
      <Panel bg="secondary" color={alpha("dark", 0.8)}>
        <Container>
          <Styled.h1>Quick Facts</Styled.h1>
          <Row>
            <Col sx={{ width: ["100%", "80%"] }}>
              <Large sx={{ fontWeight: "heading" }}>
                In 2019-2020, The Neighbourhood Group and St. Stephen’s
                Community House helped 61,451 people with 1,017,881 visits
                across priority north, east and west Toronto neighbourhoods.
              </Large>
            </Col>
          </Row>
          <Walking sx={{ my: 4, width: "100%", height: "auto" }} />
          <Row sx={{ flexDirection: ["column", "row"] }}>
            <Col sx={{ width: ["100%", "50%"] }}>
              <Styled.h2 sx={{ display: "flex", alignItems: "center" }}>
                <Virus sx={{ mr: 2 }} />
                COVID-19 Response
              </Styled.h2>
              <Styled.p>
                Thanks to our dedicated staff, volunteers and generous donors
                that helped keep our essential services going during COVID-19.
                While many of our services went virtual, some of our critical
                ones remained open, like our Overdose Prevention Site, Teesdale
                Food Bank, Meals on Wheels, Corner Drop-in and our supportive
                housing services.
              </Styled.p>
            </Col>
            <Col sx={{ width: ["100%", "50%"] }}>
              <Styled.h2 sx={{ display: "flex", alignItems: "center" }}>
                <Female sx={{ mr: 2 }} />
                Women’s &amp; Family Services
              </Styled.h2>
              <Styled.p>
                1048 women successfully managed pregnancy and motherhood,
                violence, and other critical issues in our dedicated programs
                for women and families
              </Styled.p>
              <Styled.p>
                <Button
                  variant="underlineDark"
                  as={Link}
                  to="/womens-family-services "
                >
                  View Women’s &amp; Family Services
                </Button>
              </Styled.p>
            </Col>
          </Row>
        </Container>
      </Panel>
    </section>
    <Container>
      <section>
        <Styled.h1>Services</Styled.h1>
        <GridTwo />
      </section>
    </Container>
    <section sx={{ mt: 5, mb: [4, 6] }}>
      <Container>
        <Styled.h1>Volunteers &amp; Donors</Styled.h1>
      </Container>
      <Panel>
        <Container>
          <Styled.h2 sx={{ mt: 0 }}>The heart and soul of our work</Styled.h2>
          <Medium>
            Over 2,000 members of the community, including service users,
            students, parents and grandparents, volunteered their time to
            support their neighbors.
          </Medium>
          <Styled.p>
            <Button variant="underlineLight" as={Link} to="/volunteers">
              Read more about our volunteers
            </Button>
          </Styled.p>
          <Styled.h2>Donor Roster</Styled.h2>
          <Row>
            <Col sx={{ width: ["100%", "50%"] }}>
              <a href="/donors" rel="Global News video segment">
                <Img
                  fluid={data.donor.childImageSharp.fluid}
                  alt=""
                  sx={{ borderRadius: "default", mb: [0, -6] }}
                />
              </a>
            </Col>
          </Row>
        </Container>
      </Panel>
    </section>
    <section>
      <BackgroundImage
        fluid={data.board.childImageSharp.fluid}
        sx={{ py: 5, mb: 5 }}
      >
        <Container>
          <Styled.h1 sx={{ mb: 4 }}>Board of Directors</Styled.h1>
          <Styled.p>
            <Button variant="underlineDark" as={Link} to="/board-directors">
              View our Board of Directors
            </Button>
          </Styled.p>
        </Container>
      </BackgroundImage>
    </section>
  </Layout>
)

export const query = graphql`
  query HomePageQuery {
    hero: file(relativePath: { eq: "hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bill: file(relativePath: { eq: "home-stronger-bill.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 255) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cathy: file(relativePath: { eq: "home-stronger-cathy.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 255) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    samira: file(relativePath: { eq: "home-stronger-samira.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 255) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    donor: file(relativePath: { eq: "home-decor.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 730) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    board: file(relativePath: { eq: "home-board-of-directors.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default IndexPage
