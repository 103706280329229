/** @jsx jsx */
import { jsx, Grid } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"

import GridItem from "./grid-item"

const GridTwo = () => {
  const data = useStaticQuery(graphql`
    query ServicesGridTwoQuery {
      conflict: file(
        relativePath: { eq: "home-conflict-resolution-training.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 730) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      employment: file(relativePath: { eq: "home-employment-services.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      trustee: file(relativePath: { eq: "home-trustee-services.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      childcare: file(relativePath: { eq: "home-childcare.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      community: file(relativePath: { eq: "home-community-development.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      womens: file(relativePath: { eq: "home-womens-family-services.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 730) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Grid
      sx={{ my: 5, width: "100%", gridTemplateRows: "350px" }}
      columns={3}
      gap={3}
    >
      <GridItem
        column="1 / span 2"
        row="1"
        to="/conflict-resolution-training"
        image={data.conflict.childImageSharp.fluid}
      >
        Conflict Resolution &amp; Training
      </GridItem>
      <GridItem
        column="3"
        row="1 / span 2"
        to="/employment-services"
        image={data.employment.childImageSharp.fluid}
      >
        Employment Services
      </GridItem>
      <GridItem
        column="1"
        row="2"
        to="/trustee-services"
        image={data.trustee.childImageSharp.fluid}
      >
        Trustee Services
      </GridItem>
      <GridItem
        column="2"
        row="2"
        to="/childcare"
        image={data.childcare.childImageSharp.fluid}
      >
        Childcare
      </GridItem>
      <GridItem
        column="1"
        row="3"
        to="/community-development"
        image={data.community.childImageSharp.fluid}
      >
        Community Development
      </GridItem>
      <GridItem
        column="2 / span 2"
        row="3"
        to="/womens-family-services"
        image={data.womens.childImageSharp.fluid}
      >
        Women’s &amp; Family Services
      </GridItem>
    </Grid>
  )
}

export default GridTwo
